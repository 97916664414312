/* eslint-disable no-nested-ternary */
import React, {FC, Ref, RefObject, useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';

import {EventStatus, MatchSimple, BetData, BetProviderData, Sport, BetDataAll} from 'utils/types';

import './styles.scss';

import teamHomeLogoFallback from 'assets/img/team-home-logo-placeholder.png';
import teamAwayLogoFallback from 'assets/img/team-away-logo-placeholder.png';


const MatchcenterScoreboardPanel: FC<{
  match: MatchSimple;
  betData?: BetDataAll;
  root: HTMLElement | null;
}> = ({
  match: {
    _id,
    sportEventSlug,
    sport,
    date,
    competition,
    teams,
    score,
    matchStatus,
    status,
    minute,
    stoppageTime,
    providerView,
    betMatch,
  },
  betData,

  root,
}: {
  match: MatchSimple;
  betData?: BetDataAll | undefined;
  root: HTMLElement | null;
}) => {


  return (
    root &&
    createPortal(
      <>
        <a href={`/matchcenter/${sportEventSlug}/${_id}`} className="MatchcenterScoreboardPanel">
          <h1 className="MatchcenterScoreboardPanel__header">{competition}</h1>
          <div className="MatchcenterScoreboardPanel__scoreboard">
            <h2 className="MatchcenterScoreboardPanel__teamName">{teams[0].shortName}</h2>
            <img
              className="MatchcenterScoreboardPanel__teamLogo"
              src={
                sport === Sport.TENNIS
                  ? teamHomeLogoFallback
                  : teams[0].imageUrl || teamHomeLogoFallback
              }
              width="40"
              height="40"
              alt={teams[0].name}
            />
            {status !== EventStatus.NOT_STARTED && (
              <span className="MatchcenterScoreboardPanel__teamScore">{score[0] ?? '-'}</span>
            )}
            {status === EventStatus.NOT_STARTED ? (
              <div className="MatchcenterScoreboardPanel__date">
                <span className="MatchcenterScoreboardPanel__day">
                  {date.getDate()} {date.toLocaleString('el-GR', {month: 'short'})}
                </span>
                <span className="MatchcenterScoreboardPanel__time">{`${date.getHours()}:${date
                  .getMinutes()
                  .toString()
                  .padStart(2, '0')}`}</span>
              </div>
            ) : status === EventStatus.LIVE &&
              minute !== null &&
              minute !== undefined &&
              minute !== 0 &&
              matchStatus !== 'ΗΜΙ' &&
              matchStatus !== 'BREAK' ? (
              <span className="MatchcenterScoreboardPanel__minute">
                {minute}
                {stoppageTime ? ` + ${stoppageTime}` : ''}&apos;
              </span>
            ) : (
              <span className="MatchcenterScoreboardPanel__matchStatus">{matchStatus}</span>
            )}
            {status !== EventStatus.NOT_STARTED && (
              <span className="MatchcenterScoreboardPanel__teamScore">{score[1] ?? '-'}</span>
            )}
            <img
              className="MatchcenterScoreboardPanel__teamLogo"
              src={
                sport === Sport.TENNIS
                  ? teamAwayLogoFallback
                  : teams[1].imageUrl || teamAwayLogoFallback
              }
              width="40"
              height="40"
              alt={teams[1].name}
            />
            <h2 className="MatchcenterScoreboardPanel__teamName">{teams[1].shortName}</h2>
          </div>
        </a>
        {(providerView === 'Widget' || providerView === 'All') && betData?.odds && betData.odds.length > 0 && (
          <div className="MatchcenterScoreboardPanel__betpanel">
            <h2 className="MatchcenterScoreboardPanel__betpanel__header">BET</h2>
            <div className="MatchcenterScoreboardPanel__betpanel__oddsWrapper">
              {betData.odds[0].values.map((value, i) => {
                let tileType;
                if (['MRES'].includes(betData.odds[0].type)) {
                  if( i===0) tileType = 'home';
                  else if (i === betData.odds[0].values.length - 1) tileType = 'away';
                  else tileType = 'tie';
                }
                return (
                  <a href={betData.externalLink} target="_blank" rel="external nofollow noopener noreferrer" className={`MatchcenterScoreboardPanel__betpanel__oddsTile ${tileType ? `MatchcenterScoreboardPanel__betpanel__oddsTile--${tileType}`: ''}`} key={value.id}>
                    {'label' in value && 
                      <span className='MatchcenterScoreboardPanel__betpanel__oddsLabel'>{value.label}</span>}
                      <span className='MatchcenterScoreboardPanel__betpanel__oddsValue'>
                        {value.value.toFixed(
                          Math.max(2, (value.value.toString().split('.')[1] || []).length)
                        )}
                      </span>
                  </a>
                );
              })}
            </div>
            <div className="MatchcenterScoreboardPanel__betpanel__providerLogo">
              <img src={`${betData.provider?.imageUrl}`} alt="bet-logo" />
            </div>
          </div>
        )}
      </>,
      root
    )
  );
};

export default React.memo(MatchcenterScoreboardPanel);
